import React, { Component } from "react";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLevelUp,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import {
  ArtifactBundle,
  ArtifactBundlesResponse,
  Channel,
  ChannelId,
  ChannelsResponse,
  CustomJwtPayload,
  ModelNumber,
  WriteableChannel,
} from "../../models";
import {
  compareChannelRank,
  DEFAULT_PRODUCT_MODEL_ID,
  serializeChannelId,
  toBetterTimeString,
} from "../../utils";
import {
  AddEditChannelModal,
  ArtifactBundleTableRow,
  BetterSpinner,
  ConfirmationModal,
  ProductLine,
  ReleaseNotesModal,
} from "..";
import { HTTP_CLIENT } from "../../hooks";

interface Props {
  jwt: string | null;
}

interface State {
  model: number;
  channels: Channel[];
  artifactBundles?: ArtifactBundle[];
  models?: ModelNumber[];
  expandedChannels: string[];
  error?: Error;
  loading: boolean;
  showAddEditModal: boolean;
  showConfirmationModal: boolean;
  channelToEdit?: Channel;
  channelToPromote?: Channel;
  showReleaseNotesModal: boolean;
}

export class Channels extends Component<Props, State> {
  private static readonly PATH_PREFIX = "/api/v2/channels";
  private static readonly ARTIFACT_BUNDLES_PATH_PREFIX =
    "/api/v2/artifactBundles";

  constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      model: DEFAULT_PRODUCT_MODEL_ID,
      loading: false,
      showAddEditModal: false,
      showConfirmationModal: false,
      showReleaseNotesModal: false,
      expandedChannels: [],
      channels: [],
    };
  }

  async componentDidMount(): Promise<void> {
    await this.updateList();
  }

  render() {
    return this.state.loading ? (
      <BetterSpinner />
    ) : (
      <>
        <Row>
          <Col>
            <h1>
              Channels
              {this.props.jwt && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() =>
                    this.setState({
                      showAddEditModal: true,
                      channelToEdit: undefined,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              )}
            </h1>
          </Col>
        </Row>

        <Row>
          <Form>
            <Row>
              <Col sm={4} md={3} lg={3} xl={2}>
                <ProductLine
                  size="sm"
                  onChange={async (newModel) => {
                    if (
                      undefined !== newModel &&
                      newModel !== this.state.model
                    ) {
                      this.setState({
                        model: newModel,
                      });
                      await this.updateList(newModel);
                    }
                  }}
                  value={this.state.model}
                />
              </Col>
            </Row>
          </Form>
        </Row>

        <Row>
          {this.state.loading ? (
            <p>Loading. Please wait...</p>
          ) : (
            <Table hover>
              <thead>
                <tr>
                  <th>Version</th>
                  <th>Release Files</th>
                  <th>Debug Files</th>
                  <th>Log Decoder Files</th>
                  <th>Release Notes</th>
                  <th>Build Author</th>
                  <th>Time</th>
                  <th>Source</th>
                </tr>
              </thead>

              <tbody>
                {this.state.channels
                  .filter(
                    (channel) =>
                      this.props.jwt || channel.stabilityRank !== null,
                  )
                  .map((channel) => {
                    const rows = [
                      <tr
                        key={`channel-${channel.id.name}`}
                        // Mimic that striped look
                        style={{
                          background: "rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        <td
                          colSpan={7}
                          style={{ cursor: "pointer" }}
                          onClick={async () => this.expandChannel(channel)}
                        >
                          {channel.stabilityRank && (
                            <Badge style={{ marginRight: "1em" }}>
                              {channel.stabilityRank}
                            </Badge>
                          )}
                          <strong>
                            {channel.id.name.replaceAll("_-_", "/")}
                          </strong>
                          <small style={{ float: "right" }}>
                            Last updated:{" "}
                            <strong>
                              {toBetterTimeString(channel.lastUpdatedTime)}
                            </strong>{" "}
                            by{" "}
                            <strong
                              style={{
                                width: "13em",
                                display: "inline-flex",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {channel.lastUpdatedBy}
                            </strong>
                          </small>
                        </td>
                        <td>
                          {this.props.jwt && (
                            <>
                              <Button
                                title="Edit channel"
                                style={{ marginLeft: "1em" }}
                                size="sm"
                                onClick={() =>
                                  this.setState({
                                    showAddEditModal: true,
                                    channelToEdit: channel,
                                  })
                                }
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </Button>

                              {channel.stabilityRank &&
                                channel.stabilityRank !== 1 && (
                                  <Button
                                    title="Promote firmware"
                                    style={{ marginLeft: "1em" }}
                                    size="sm"
                                    variant="info"
                                    onClick={() => {
                                      this.setState({
                                        showConfirmationModal: true,
                                        channelToPromote: channel,
                                      });
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faLevelUp} />
                                  </Button>
                                )}

                              {channel.stabilityRank === null && (
                                <Button
                                  title="Delete channel"
                                  style={{ marginLeft: "1em" }}
                                  size="sm"
                                  variant="danger"
                                  onClick={() =>
                                    this.setState({
                                      showConfirmationModal: true,
                                      channelToEdit: channel,
                                    })
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              )}
                            </>
                          )}
                        </td>
                      </tr>,
                    ];
                    if (
                      channel.artifactBundle &&
                      this.state.expandedChannels.includes(channel.id.name)
                    ) {
                      rows.push(
                        <ArtifactBundleTableRow
                          key={`channel-${channel.id.name}-firmware`}
                          bundle={channel.artifactBundle}
                          model={this.state.model}
                          jwt={this.props.jwt}
                          editReleaseNotesClicked={() => {
                            this.setState({
                              showReleaseNotesModal: true,
                              channelToEdit: channel,
                            });
                          }}
                        />,
                      );
                    }
                    return rows;
                  })}
              </tbody>
            </Table>
          )}
        </Row>

        {this.props.jwt && (
          <AddEditChannelModal
            initialChannelList={this.state.channels}
            artifactBundles={this.state.artifactBundles}
            models={this.state.models}
            user={jwtDecode<CustomJwtPayload>(this.props.jwt).sub!}
            show={this.state.showAddEditModal}
            close={() => this.setState({ showAddEditModal: false })}
            initialChannel={this.state.channelToEdit}
            onSave={async (channel) => {
              await this.onSave(channel);
            }}
            computeNewChannelOrder={(channelToEdit) =>
              this.computeNewOrder(channelToEdit)
            }
          />
        )}

        {this.props.jwt && (
          <ConfirmationModal
            show={
              this.state.showConfirmationModal && !!this.state.channelToEdit
            }
            onHide={() =>
              this.setState({
                showConfirmationModal: false,
                channelToEdit: undefined,
              })
            }
            text={
              <>
                delete{" "}
                <code>
                  {this.state.channelToEdit?.id?.name?.replaceAll("_-_", "/")}
                </code>{" "}
                ({this.state.channelToEdit?.id?.model})
              </>
            }
            onConfirm={async () => {
              await HTTP_CLIENT.delete({
                path: `${Channels.PATH_PREFIX}/${
                  this.state.channelToEdit!.id!.name
                }+${this.state.channelToEdit!.id!.model}`,
                headers: { Authorization: `Bearer ${this.props.jwt}` },
              });
              await this.updateList();
              this.setState({
                showConfirmationModal: false,
                channelToEdit: undefined,
              });
            }}
            confirmationButtonText={"Delete"}
          />
        )}

        {this.props.jwt && (
          <ConfirmationModal
            show={
              this.state.showConfirmationModal && !!this.state.channelToPromote
            }
            onHide={() =>
              this.setState({
                showConfirmationModal: false,
                channelToPromote: undefined,
              })
            }
            text={
              <>
                promote{" "}
                <code>
                  {this.state.channelToPromote?.artifactBundle?.version}
                </code>{" "}
                to{" "}
                <code>
                  {
                    this.getPromotionTarget(this.state.channelToPromote)?.id
                      ?.name
                  }
                </code>{" "}
                (
                {
                  this.getPromotionTarget(this.state.channelToPromote)?.id
                    ?.model
                }
                )
              </>
            }
            onConfirm={async () => {
              const firmwareToPromote =
                this.state.channelToPromote!.artifactBundle!;
              const destinationChannel = this.getPromotionTarget(
                this.state.channelToPromote,
              )!;

              const lastUpdatedBy = jwtDecode<CustomJwtPayload>(
                this.props.jwt || "",
              ).sub!;
              const lastUpdatedTime = new Date().toISOString();

              // Pin the firmware, if needed
              if (!firmwareToPromote.pinned) {
                await HTTP_CLIENT.patch({
                  path: `${Channels.ARTIFACT_BUNDLES_PATH_PREFIX}/${firmwareToPromote.id}`,
                  headers: { Authorization: `Bearer ${this.props.jwt}` },
                  body: { pinned: true },
                });
              }

              // Promote the firmware
              await HTTP_CLIENT.patch({
                path: `${Channels.PATH_PREFIX}/${destinationChannel.id.name}+${destinationChannel.id.model}`,
                headers: { Authorization: `Bearer ${this.props.jwt}` },
                body: {
                  firmware: firmwareToPromote._links.self.href,
                  lastUpdatedBy,
                  lastUpdatedTime,
                },
              });

              // Update the table
              await this.updateList();
              this.setState({
                showConfirmationModal: false,
                channelToPromote: undefined,
              });
            }}
            confirmationButtonText={"PROMOTE"}
          />
        )}

        <ReleaseNotesModal
          show={this.state.showReleaseNotesModal}
          onHide={() =>
            this.setState({
              showReleaseNotesModal: false,
              channelToEdit: undefined,
            })
          }
          jwt={this.props.jwt}
          onSubmit={async (content) => {
            if (
              content !== this.state.channelToEdit!.artifactBundle!.releaseNotes
            ) {
              await this.updateReleaseNotes(content);
            }
            this.setState({
              showReleaseNotesModal: false,
              channelToEdit: undefined,
            });
          }}
          initialContent={
            this.state.channelToEdit?.artifactBundle?.releaseNotes || null
          }
        />
      </>
    );
  }

  private async updateList(model?: number): Promise<void> {
    try {
      this.setState({ loading: true });
      const channelResponse = await HTTP_CLIENT.get<ChannelsResponse>({
        path: `${Channels.PATH_PREFIX}/search/findByIdModel`,
        query: { model: model || this.state.model },
      });
      const artifactBundlesResponsePromise =
        HTTP_CLIENT.get<ArtifactBundlesResponse>({
          path: Channels.ARTIFACT_BUNDLES_PATH_PREFIX,
        });

      const channels = await Promise.all(
        channelResponse._embedded.channels.map(
          async (channel): Promise<Channel> => ({
            ...channel,
            artifactBundle: await this.loadArtifactBundleForChannel(channel),
          }),
        ),
      );
      this.setState({
        channels: channels.sort(compareChannelRank),
        loading: false,
        error: undefined,
      });

      // Allow the full FW list to load in the background. It is only needed when the user wants to manually set the FW
      // version for a channel and needs to select that FW version from the dropdown.
      const bundleResponse = await artifactBundlesResponsePromise;
      this.setState({
        artifactBundles: bundleResponse._embedded.artifactBundles,
      });
    } catch (e: any) {
      console.error(e.stack);
      this.setState({
        loading: false,
        error: new Error(
          `Failed to retrieve channels (or firmware images) due to:\n${e.message}`,
        ),
      });
    }
  }

  private expandChannel(channel: Channel) {
    if (this.state.expandedChannels.includes(channel.id.name))
      this.setState({
        expandedChannels: this.state.expandedChannels.filter(
          (name) => name !== channel.id.name,
        ),
      });
    else
      this.setState({
        expandedChannels: this.state.expandedChannels.concat(channel.id.name),
      });
  }

  private async loadArtifactBundleForChannel(
    channel: Channel,
  ): Promise<ArtifactBundle> {
    if (channel.artifactBundle) {
      return channel.artifactBundle;
    } else {
      return await HTTP_CLIENT.get<ArtifactBundle>({
        path: channel._links.artifactBundle.href,
      });
    }
  }

  private async onSave(newOrModifiedChannel: WriteableChannel): Promise<void> {
    try {
      await this.saveAll(
        newOrModifiedChannel,
        this.getSortedChannelsToSave(newOrModifiedChannel),
        this.state.channels.map((oldChannel) => oldChannel.id),
      );
      await this.updateList();
    } catch (e: any) {
      console.error(e.stack);
      toast.error("Failed to save channel!");
    } finally {
      this.setState({ showAddEditModal: false, channelToEdit: undefined });
    }
  }

  private async saveAll(
    newOrModifiedChannel: WriteableChannel,
    sortedChannelsToSave: WriteableChannel[],
    preExistingChannelIds: ChannelId[],
  ): Promise<void> {
    // We need to make room to shuffle all the other modified channels into their new positions, so do a temporary
    // write that moves the directly-modified channel out of the way of the others.
    //
    // Skip this step if the rank is already null though

    const originalChannelBeingModified = this.state.channels.find(
      (channel) => channel.id === newOrModifiedChannel.id,
    );

    if (
      !!originalChannelBeingModified?.stabilityRank &&
      sortedChannelsToSave.length > 1
    ) {
      await this.saveOne(
        { ...newOrModifiedChannel, stabilityRank: null },
        preExistingChannelIds,
      );
      preExistingChannelIds.push(newOrModifiedChannel.id);
    }

    for (const channel of sortedChannelsToSave) {
      // Some optimization could be made such that we don't double-save the directly-modified channel in the case that
      // the ONLY modification was to its rank and that the modification was moving from ranked to unranked. But,
      // I've already spent too much time on the ranking logic and don't care to optimize it any more.
      await this.saveOne(channel, preExistingChannelIds);
    }
  }

  private async saveOne(
    channel: WriteableChannel,
    preExistingChannelNames: ChannelId[],
  ): Promise<void> {
    const isChannelNew = preExistingChannelNames.includes(channel.id);

    const { id, firmware } = channel;

    // So, we actually have a mix of Channel and Writeable channels in here. Figure that crap out.
    // noinspection SuspiciousTypeOfGuard
    const firmwareHref =
      typeof firmware === "string"
        ? firmware
        : // @ts-ignore
          firmware?._links?.self?.href || channel._links.firmware.href;

    return HTTP_CLIENT.request(isChannelNew ? "PATCH" : "POST", {
      path: `${Channels.PATH_PREFIX}${isChannelNew ? `/${serializeChannelId(id)}` : ""}`,
      headers: { Authorization: `Bearer ${this.props.jwt}` },
      body: {
        ...channel,
        firmware: firmwareHref,
      },
    });
  }

  private getSortedChannelsToSave(
    newOrModifiedChannel: WriteableChannel,
  ): WriteableChannel[] {
    const newChannelOrder = this.computeNewOrder(newOrModifiedChannel);

    const newMappingIdToIndex: Record<string, number | null> =
      Object.fromEntries(
        newChannelOrder.map((channel) => [
          serializeChannelId(channel.id),
          channel.stabilityRank,
        ]),
      );
    const oldMappingIdToIndex: Record<string, number | null> =
      Object.fromEntries(
        this.state.channels.map((channel) => [
          serializeChannelId(channel.id),
          channel.stabilityRank,
        ]),
      );

    const channelIdsWithModifiedRank = Object.entries(newMappingIdToIndex)
      .filter(([name, newRank]) => oldMappingIdToIndex[name] !== newRank)
      .map(([name]) => name);

    if (channelIdsWithModifiedRank.length) {
      const oldChannel: Channel | undefined = this.state.channels.filter(
        (channel) => channel.id === newOrModifiedChannel.id,
      )[0];

      const sortedChannelsThatNeedModifying = newChannelOrder.filter(({ id }) =>
        channelIdsWithModifiedRank.includes(serializeChannelId(id)),
      );

      // If new is unranked or if new is sorted with lower priority than old, update all modified channels in rank
      // order AFTER the new channel has been (maybe temporarily) marked as unranked.
      // Otherwise, update all modified channels in reverse rank order.
      // "Rank order" in this context is referring to the new rank order.
      if (
        newOrModifiedChannel.stabilityRank === null ||
        0 < compareChannelRank(newOrModifiedChannel, oldChannel)
      ) {
        return sortedChannelsThatNeedModifying;
      } else {
        return sortedChannelsThatNeedModifying.reverse();
      }
    } else {
      return [newOrModifiedChannel];
    }
  }

  private computeNewOrder<T extends Pick<Channel, "id" | "stabilityRank">>(
    channelToEdit: T,
  ): T[] {
    // Start this process by creating a new list that does not contain the channel being modified
    let newList: any[] = [
      ...this.state.channels.filter(
        (channel) => channel.id.name !== channelToEdit.id.name,
      ),
    ]
      // Create a copy of each channel so that we can modify ranks in the next step without mutating the original
      // objects
      .map((channel) => ({ ...channel }));

    if (channelToEdit.stabilityRank)
      newList = newList
        .slice(0, channelToEdit.stabilityRank - 1)
        .concat(channelToEdit)
        .concat(newList.slice(channelToEdit.stabilityRank - 1));
    else newList.push(channelToEdit);

    newList.forEach((channel, index) => {
      if (channel.stabilityRank) channel.stabilityRank = index + 1;
    });

    return newList.sort(compareChannelRank);
  }

  private getPromotionTarget(
    channelToPromote: Channel | undefined,
  ): Channel | undefined {
    if (
      channelToPromote &&
      !!channelToPromote.stabilityRank &&
      channelToPromote.stabilityRank > 1
    )
      return this.state.channels[channelToPromote.stabilityRank - 2];
  }

  private async updateReleaseNotes(releaseNotes: string | null): Promise<void> {
    try {
      await HTTP_CLIENT.patch({
        path: `${Channels.ARTIFACT_BUNDLES_PATH_PREFIX}/${this.state.channelToEdit!.artifactBundle!.id}`,
        headers: { Authorization: `Bearer ${this.props.jwt}` },
        body: { releaseNotes },
      });
      await this.updateList();
    } catch (e: any) {
      console.error(e.stack);
      toast.error("Failed to save firmware. See console for details");
    }
  }
}
