import { InMemoryDownload } from "../InMemoryDownload";
import { ArtifactFileList } from "./ArtifactFileList";
import { ArtifactBundle } from "../../models";

interface Props {
  bundleFiles: ArtifactFileList;
  bundle: ArtifactBundle;
}

export function Tr2FileList({ bundleFiles, bundle }: Props) {
  const appAndBlName = `token_mcuboot_and_app.${bundle.version}.bin`;

  return (
    <>
      <td>
        <InMemoryDownload
          displayName="OTA Updatable App"
          fileName={bundle.version}
          content={bundleFiles.releaseAppOnly?.data}
        />
        {bundleFiles.releaseAppAndBootloaderDevKeys && (
          <>
            <br />
            <InMemoryDownload
              displayName="App + Bootloader"
              fileName={appAndBlName}
              content={bundleFiles.releaseAppAndBootloaderDevKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseAppOnlyElf && (
          <>
            <br />
            <InMemoryDownload
              displayName="OTA Updatable App (Elf)"
              fileName={`${bundle.version}.elf`}
              content={bundleFiles.releaseAppOnlyElf?.data}
            />
          </>
        )}
        {bundleFiles.releaseFimeTest && (
          <>
            <br />
            <InMemoryDownload
              displayName="fime_test.bin"
              fileName="fime_test.bin"
              content={bundleFiles.releaseFimeTest?.data}
            />
          </>
        )}
        {bundleFiles.releaseMfg && (
          <>
            <br />
            <InMemoryDownload
              displayName="Manufacturing"
              fileName="mfg.bin"
              content={bundleFiles.releaseMfg?.data}
            />
          </>
        )}
        {bundleFiles.releaseMfgElf && (
          <>
            <br />
            <InMemoryDownload
              displayName="Manufacturing (Elf)"
              fileName="mfg.elf"
              content={bundleFiles.releaseMfgElf?.data}
            />
          </>
        )}
        {bundleFiles.bootloaderRelease && (
          <>
            <br />
            <InMemoryDownload
              displayName="Bootloader"
              fileName="bootloader.bin"
              content={bundleFiles.bootloaderRelease?.data}
            />
          </>
        )}
        {bundle.releaseMeta && (
          <>
            <br />
            <InMemoryDownload
              displayName="Metadata"
              fileName="metadata.json"
              content={window.btoa(bundle.releaseMeta)}
            />
          </>
        )}
      </td>
      <td>
        {bundleFiles.debugAppOnly && (
          <InMemoryDownload
            displayName="OTA Updatable App"
            fileName={bundle.vcsCommit}
            content={bundleFiles.debugAppOnly?.data}
          />
        )}
        {bundleFiles.debugAppAndBootloader && (
          <>
            <br />
            <InMemoryDownload
              displayName="App + Bootloader"
              fileName={appAndBlName}
              content={bundleFiles.debugAppAndBootloader?.data}
            />
          </>
        )}
        {bundleFiles.debugFimeTest && (
          <>
            <br />
            <InMemoryDownload
              displayName="fime_test.bin"
              fileName="fime_test.bin"
              content={bundleFiles.debugFimeTest?.data}
            />
          </>
        )}
        {bundle.debugMeta && (
          <>
            <br />
            <InMemoryDownload
              displayName="Metadata"
              fileName="metadata.json"
              content={window.btoa(bundle.debugMeta)}
            />
          </>
        )}
      </td>
      <td>
        {bundleFiles.translationsFile && (
          <InMemoryDownload
            displayName="til.json"
            fileName="til.json"
            content={bundleFiles.translationsFile?.data}
          />
        )}
        {bundleFiles.translationsFile && bundleFiles.locationsFile && <br />}
        {bundleFiles.locationsFile && (
          <InMemoryDownload
            displayName="li.json"
            fileName="li.json"
            content={bundleFiles.locationsFile?.data}
          />
        )}
      </td>
    </>
  );
}
