import React, { useCallback, useEffect, useState } from "react";
import { ArtifactBundle, ArtifactBundlesResponse } from "../../models";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { ArtifactBundleTableRow } from "../ArtifactBundleTableRow";
import { HTTP_CLIENT } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { SubmitNewArtifactsModal } from "../SubmitNewArtifactsModal";
import { ReleaseNotesModal } from "../ReleaseNotesModal";
import { toast } from "react-toastify";
import { ProductLine } from "../FormFields";
import { DEFAULT_PRODUCT_MODEL_ID } from "../../utils";

const PATH_PREFIX = "/api/v2/artifactBundles";

interface Props {
  jwt: string | null;
}

export const ArtifactBundles = ({ jwt }: Props) => {
  const [model, setModel] = useState<number>(DEFAULT_PRODUCT_MODEL_ID);
  const [artifactBundles, setArtifactBundles] = useState<ArtifactBundle[]>();
  const [loading, setLoading] = useState(true);
  const [showSubmitNewFwModal, setShowSubmitNewFwModal] = useState(false);
  const [showReleaseNotesModal, setShowReleaseNotesModal] = useState(false);
  const [bundleToEdit, setBundleToEdit] = useState<ArtifactBundle>();

  const updateList = useCallback(async (model: number) => {
    try {
      const bundlesResponse = await HTTP_CLIENT.get<ArtifactBundlesResponse>({
        path: `${PATH_PREFIX}/search/findByModel`,
        query: { model },
      });
      setArtifactBundles(bundlesResponse._embedded.artifactBundles);
    } catch (e: any) {
      console.error(e.stack);
      setArtifactBundles(undefined);
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // Errors are handled inside the call to updateList()
    void updateList(model);
  }, [updateList, model]);

  const updateReleaseNotes = async (releaseNotes: string | null) => {
    try {
      await HTTP_CLIENT.patch({
        path: `${PATH_PREFIX}/${bundleToEdit!.id}`,
        headers: { Authorization: `Bearer ${jwt}` },
        body: { releaseNotes },
      });
      await updateList(model);
    } catch (e: any) {
      console.error(e.stack);
      toast.error("Failed to save firmware. See console for details");
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h1>
            Artifact Bundles
            {jwt && (
              <Button
                variant="primary"
                size="sm"
                onClick={() => setShowSubmitNewFwModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </h1>
        </Col>
      </Row>

      <Row>
        <Form>
          <Row>
            <Col sm={4} md={3} lg={3} xl={2}>
              <ProductLine
                size="sm"
                onChange={async (newModel) => {
                  if (undefined !== newModel && newModel !== model) {
                    setModel(newModel);
                  }
                }}
                value={model}
              />
            </Col>
          </Row>
        </Form>
      </Row>

      <Row>
        {loading ? (
          <p>Loading. Please wait...</p>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Version</th>
                <th>Release Files</th>
                <th>Debug Files</th>
                <th>Log Decoder Files</th>
                <th>Release Notes</th>
                <th>Build Author</th>
                <th>Time</th>
                <th>Source</th>
              </tr>
            </thead>

            <tbody>
              {artifactBundles?.map((bundle) => (
                <ArtifactBundleTableRow
                  key={`fw-${bundle.id}`}
                  bundle={bundle}
                  model={model}
                  jwt={jwt}
                  editReleaseNotesClicked={() => {
                    setShowReleaseNotesModal(true);
                    setBundleToEdit(bundle);
                  }}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Row>

      <SubmitNewArtifactsModal
        show={showSubmitNewFwModal}
        close={() => setShowSubmitNewFwModal(false)}
        onSubmit={async (buildUrl) => {
          setLoading(true);
          setShowSubmitNewFwModal(false);
          try {
            await HTTP_CLIENT.post({
              path: "/api/v2/build/download",
              query: { buildUrl },
              headers: { Authorization: `Bearer ${jwt}` },
            });
            await updateList(model);
          } catch (e: any) {
            console.error(e.stack);
            toast.error(e.message);
          } finally {
            setLoading(false);
          }
        }}
      />

      {jwt && (
        <ReleaseNotesModal
          show={showReleaseNotesModal}
          onHide={() => {
            setShowReleaseNotesModal(false);
            setBundleToEdit(undefined);
          }}
          jwt={jwt}
          onSubmit={async (content) => {
            if (content !== bundleToEdit!.releaseNotes) {
              await updateReleaseNotes(content);
            }
            setShowReleaseNotesModal(false);
          }}
          initialContent={bundleToEdit?.releaseNotes || null}
        />
      )}
    </>
  );
};
