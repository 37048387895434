import React from "react";
import { InMemoryDownload } from "../InMemoryDownload";
import { ArtifactBundle } from "../../models";
import { ArtifactFileList } from "./ArtifactFileList";

interface Props {
  bundleFiles: ArtifactFileList;
  bundle: ArtifactBundle;
}

export function Tr3FirmwareFileList({ bundleFiles, bundle }: Props) {
  return (
    <>
      <td>
        {bundleFiles.releaseAppAndBootloaderDevKeys && (
          <>
            <InMemoryDownload
              displayName="App + Bootloader (Dev Keys)"
              fileName={`app-bootloader-bundle-dev_${bundle.version}.bin`}
              content={bundleFiles.releaseAppAndBootloaderDevKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseAppAndBootloaderProdKeys && (
          <>
            <br />
            <InMemoryDownload
              displayName="App + Bootloader (Prod Keys)"
              fileName={`app-bootloader-bundle-prod_${bundle.version}.bin`}
              content={bundleFiles.releaseAppAndBootloaderProdKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseAppOnlyEncryptedDevKeys && (
          <>
            <br />
            <InMemoryDownload
              displayName="OTA Updateable App (Dev Keys)"
              fileName={`halo-dev_${bundle.version}.sfb`}
              content={bundleFiles.releaseAppOnlyEncryptedDevKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseAppOnlyEncryptedProdKeys && (
          <>
            <br />
            <InMemoryDownload
              displayName="OTA Updateable App (Prod Keys)"
              fileName={`halo-prod_${bundle.version}.sfb`}
              content={bundleFiles.releaseAppOnlyEncryptedProdKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseAppOnly && (
          <>
            <br />
            <InMemoryDownload
              displayName="Unencrypted App Firmware (bin)"
              fileName={`halo_${bundle.version}.bin`}
              content={bundleFiles.releaseAppOnly?.data}
            />
          </>
        )}
        {bundle.releaseMeta && (
          <>
            <br />
            <InMemoryDownload
              displayName="Metadata"
              fileName="metadata.json"
              content={window.btoa(bundle.releaseMeta)}
            />
          </>
        )}
      </td>

      <td></td>

      <td>
        {bundleFiles.releaseAppOnlyElf && (
          <>
            <InMemoryDownload
              displayName="Application ELF"
              fileName={`${bundle.version}.elf`}
              content={bundleFiles.releaseAppOnlyElf?.data}
            />
          </>
        )}
        {bundleFiles.releaseMfgElf && (
          <>
            <br />
            <InMemoryDownload
              displayName="Manufacturing Elf"
              fileName="mfg.elf"
              content={bundleFiles.releaseMfgElf?.data}
            />
          </>
        )}
      </td>
    </>
  );
}
