import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import { ChannelMap, ChannelMapId, WriteableChannelMap } from "../../models";
import { NewChannelMap } from "./models";

interface Props {
  show: boolean;
  close: () => void;
  initialChannelMap?: Partial<Omit<ChannelMap, "id">> & {
    id: Partial<ChannelMapId>;
  };
  onSave: (newChannelMap: WriteableChannelMap) => Promise<void> | void;
}

export function AddEditChannelMapModal({
  show,
  close,
  initialChannelMap,
  onSave,
}: Props) {
  const [newChannelMap, setNewChannelMap] = useState<Partial<NewChannelMap>>(
    initialChannelMap || {},
  );

  const getName = () =>
    newChannelMap?.id?.channelName || initialChannelMap?.id?.channelName;
  const getModel = () =>
    newChannelMap?.id?.model || initialChannelMap?.id?.model;
  const getRef = () => newChannelMap.gitRef || initialChannelMap?.gitRef;

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        {initialChannelMap ? "Edit" : "Create"} Channel Map
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group controlId="channelName">
                <Form.Label>Channel Name</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Channel Name"
                  value={getName()}
                  onChange={(e) => {
                    setNewChannelMap({
                      ...newChannelMap,
                      id: {
                        ...newChannelMap.id,
                        channelName: e.target.value,
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="gitRef">
                <Form.Label>Git Ref</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="main"
                  value={getRef()}
                  onChange={(e) => {
                    setNewChannelMap({
                      ...newChannelMap,
                      gitRef: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type={"submit"}
          disabled={!getName() || !getRef()}
          onClick={async () => {
            const channelMap = {
              id: { channelName: getName()!, model: getModel()! },
              gitRef: getRef()!,
            };
            console.log(JSON.stringify(channelMap));
            await onSave(channelMap);
            setNewChannelMap({});
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
