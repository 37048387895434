import { Button, Form, Modal } from "react-bootstrap";
import React, { useState } from "react";

interface Props {
  show: boolean;
  close: () => void;
  onSubmit: (url: string) => Promise<void> | void;
}

export function SubmitNewArtifactsModal({ show, close, onSubmit }: Props) {
  const [buildUrl, setBuildUrl] = useState<string>();

  return (
    <Modal show={show} onHide={close} size={"xl"}>
      <Modal.Header closeButton>
        Download New Artifacts from CI Server
      </Modal.Header>

      <Modal.Body>
        <Form.Label>Build URL</Form.Label>
        <Form.Control
          aria-label="buildUrl"
          type="text"
          placeholder="https://token.teamcity.com/buildConfiguration/Engineering_Firmware_RingLegacy_BuildPreReleaseFirmware/2983"
          onChange={(e) => setBuildUrl(e.target.value)}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type={"submit"}
          disabled={!buildUrl}
          onClick={() => onSubmit(buildUrl!)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
