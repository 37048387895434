import React, { useCallback, useState } from "react";
import { BinaryBlobResponse, ArtifactBundle } from "../../models";
import { HTTP_CLIENT } from "../../hooks";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { ReleaseNotesButton } from "../ReleaseNotesModal";
import { Button } from "react-bootstrap";
import { Tr3FirmwareFileList } from "./Tr3FirmwareFileList";
import { Tr3BootloaderFileList } from "./Tr3BootloaderFileList";
import { Tr2FileList } from "./Tr2FileList";
import { ArtifactFileList } from "./ArtifactFileList";

const BINARY_BLOBS_PATH_PREFIX = "/api/v2/binaryBlobs";

interface Props {
  bundle: ArtifactBundle;
  model: number;
  jwt: string | null;
  editReleaseNotesClicked: () => void;
}

function fileList(
  model: number,
  bundle: ArtifactBundle,
  bundleFiles: ArtifactFileList,
) {
  switch (model) {
    case 1:
      return <Tr2FileList bundleFiles={bundleFiles} bundle={bundle} />;
    case 2:
      return <Tr3FirmwareFileList bundleFiles={bundleFiles} bundle={bundle} />;
    case 3:
      return (
        <Tr3BootloaderFileList bundleFiles={bundleFiles} bundle={bundle} />
      );
    default:
      return (
        <>
          Model {model} is unsupported. Go away, I'm busy with important things.
        </>
      );
  }
}

export function ArtifactBundleTableRow({
  bundle,
  model,
  jwt,
  editReleaseNotesClicked,
}: Props) {
  const [bundleLoadTriggered, setBundleLoadTriggered] = useState(false);
  const [bundleFiles, setBundleFiles] = useState<ArtifactFileList>({});

  const updateBlobsList = useCallback(async () => {
    const blobsResponse = await HTTP_CLIENT.get<BinaryBlobResponse>({
      path: `${BINARY_BLOBS_PATH_PREFIX}/search/findByArtifactBundle`,
      query: { bundle: bundle.id },
    });
    const files = blobsResponse._embedded.binaryBlobs;
    files.forEach((file) => {
      console.log(`Got binary blob with file type ${file.dataTypeRaw}`);
    });
    setBundleFiles({
      releaseAppOnly: files.find((b) => b.dataTypeRaw === 1),
      releaseAppAndBootloaderDevKeys: files.find((b) => b.dataTypeRaw === 2),
      releaseFimeTest: files.find((b) => b.dataTypeRaw === 3),
      bootloaderRelease: files.find((b) => b.dataTypeRaw === 4),
      debugAppOnly: files.find((b) => b.dataTypeRaw === 5),
      debugAppAndBootloader: files.find((b) => b.dataTypeRaw === 6),
      debugFimeTest: files.find((b) => b.dataTypeRaw === 7),
      bootloaderDebug: files.find((b) => b.dataTypeRaw === 8),
      locationsFile: files.find((b) => b.dataTypeRaw === 9),
      translationsFile: files.find((b) => b.dataTypeRaw === 10),
      releaseAppOnlyElf: files.find((b) => b.dataTypeRaw === 11),
      releaseMfgElf: files.find((b) => b.dataTypeRaw === 12),
      bootloaderReleaseElf: files.find((b) => b.dataTypeRaw === 13),
      releaseMfg: files.find((b) => b.dataTypeRaw === 14),
      releaseAppOnlyEncryptedDevKeys: files.find((b) => b.dataTypeRaw === 15),
      releaseMfgEncryptedDevKeys: files.find((b) => b.dataTypeRaw === 16),
      releaseAppOnlyEncryptedProdKeys: files.find((b) => b.dataTypeRaw === 17),
      releaseMfgEncryptedProdKeys: files.find((b) => b.dataTypeRaw === 18),
      releaseRebootstrapper: files.find((b) => b.dataTypeRaw === 19),
      releaseRebootstrapperElf: files.find((b) => b.dataTypeRaw === 20),
      releaseAppAndBootloaderProdKeys: files.find((b) => b.dataTypeRaw === 21),
      releaseRebootstrapperSfbDevKeys: files.find((b) => b.dataTypeRaw === 22),
      releaseRebootstrapperSfbProdKeys: files.find((b) => b.dataTypeRaw === 23),
      releaseRebootstrapperBundleDevKeys: files.find(
        (b) => b.dataTypeRaw === 24,
      ),
    });
  }, [bundle]);

  return (
    <tr>
      <td>
        <FontAwesomeIcon
          icon={faThumbTack}
          style={{
            marginRight: "0.8em",
            opacity: bundle.pinned ? 1 : 0,
          }}
        />
        <code>{bundle.version}</code>
      </td>
      {bundleLoadTriggered ? (
        fileList(model, bundle, bundleFiles)
      ) : (
        <td colSpan={3}>
          <Button
            size="sm"
            onClick={async () => {
              setBundleLoadTriggered(true);
              try {
                await updateBlobsList();
              } catch (e: any) {
                toast.error(e.message);
              }
            }}
          >
            Load Files
          </Button>
        </td>
      )}
      <td>
        <ReleaseNotesButton
          jwt={jwt}
          releaseNotes={bundle.releaseNotes}
          onClick={editReleaseNotesClicked}
        />
      </td>
      <td>{bundle.buildAuthor}</td>
      <td>
        Uploaded: {bundle.uploadTime}
        <br />
        Compiled: {bundle.buildTime}
      </td>
      <td>
        <a href={bundle.buildUrl} target="_blank" rel="noreferrer">
          {bundle.vcsCommit.substring(0, 7)}
        </a>
      </td>
    </tr>
  );
}
