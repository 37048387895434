import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import { micromark } from "micromark";
import { jwtDecode } from "jwt-decode";
import { CustomJwtPayload } from "../../models";
import DOMPurify from "dompurify";
import TurndownService from "turndown";

interface Props {
  jwt: string | null;
  show: boolean;
  onHide: () => void;
  onSubmit: (releaseNotes: string | null) => void;
  initialContent: string | null;
}

export function ReleaseNotesModal({
  jwt,
  show,
  onHide,
  onSubmit,
  initialContent,
}: Props) {
  const [mdReleaseNotes, setMdReleaseNotes] = useState("");
  const editable = jwt
    ? jwtDecode<CustomJwtPayload>(jwt).authorities.includes("ADMINISTRATOR")
    : false;

  useEffect(() => {
    const turndown = new TurndownService();
    setMdReleaseNotes(turndown.turndown(initialContent || "") as string);
  }, [initialContent]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton={true}>Release Notes</Modal.Header>
      <Modal.Body>
        {editable ? (
          <MDEditor
            value={mdReleaseNotes || ""}
            onChange={(newContent) =>
              setMdReleaseNotes(!!newContent ? newContent : "")
            }
          />
        ) : (
          <MDEditor.Markdown source={mdReleaseNotes} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>

        {editable && (
          <Button
            variant="primary"
            onClick={() =>
              onSubmit(
                !!mdReleaseNotes
                  ? DOMPurify.sanitize(micromark(mdReleaseNotes))
                  : null,
              )
            }
          >
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
