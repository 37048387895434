import React, { useCallback, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { ChannelMap, ChannelMapsResponse } from "../../models";
import { toast } from "react-toastify";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddEditChannelMapModal } from "../AddEditChannelMapModal";
import { HTTP_CLIENT } from "../../hooks";
import { ProductLine } from "../FormFields";
import { DEFAULT_PRODUCT_MODEL_ID } from "../../utils";

const PATH_PREFIX = "/api/v2/channelMaps";

interface Props {
  jwt: string | null;
}

export function ChannelMaps({ jwt }: Props) {
  const [model, setModel] = useState<number>(DEFAULT_PRODUCT_MODEL_ID);
  const [channelMaps, setChannelMaps] = useState<ChannelMap[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [mapToEdit, setMapToEdit] = useState<ChannelMap | undefined>();

  const updateChannelMapList = useCallback(async (): Promise<void> => {
    try {
      const channelMapsResponse = await HTTP_CLIENT.get<ChannelMapsResponse>({
        path: `${PATH_PREFIX}/search/findAllByIdModel`,
        query: { model },
      });
      setChannelMaps(channelMapsResponse._embedded.channelMaps);
      setLoading(false);
    } catch (e: any) {
      console.error(e.stack);
      setChannelMaps(undefined);
      toast.error(`Failed to retrieve channel map due to:\n${e.message}`);
      setLoading(false);
    }
  }, [model, setLoading, setChannelMaps]);

  if (undefined === channelMaps) {
    void updateChannelMapList();
  }

  return (
    <>
      <Row>
        <Col>
          <h1>
            Channel Maps
            {jwt && (
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setMapToEdit(undefined);
                  setShowModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </h1>
        </Col>
      </Row>

      <Row>
        <Form>
          <Row>
            <Col sm={4} md={3} lg={3} xl={2}>
              <ProductLine
                size="sm"
                onChange={(newModel) => {
                  if (undefined !== newModel && newModel !== model) {
                    console.log(`Setting model ${newModel}`);
                    setModel(newModel);
                    setLoading(true);
                    setChannelMaps(undefined);
                  }
                }}
                value={model}
              />
            </Col>
          </Row>
        </Form>
      </Row>

      <Row>
        {loading ? (
          <p>Loading. Please wait...</p>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <th>Channel</th>
                <th>Git Ref</th>
                {jwt && <th>Edit</th>}
              </tr>
            </thead>

            <tbody>
              {channelMaps?.map((map) => (
                <tr key={`channel-map-${map.id.channelName}`}>
                  <td>{map.id.channelName}</td>
                  <td>{map.gitRef}</td>
                  {jwt && (
                    <td>
                      {" "}
                      <Button
                        size="sm"
                        onClick={() => {
                          setMapToEdit(map);
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Row>

      <AddEditChannelMapModal
        show={showModal}
        close={() => setShowModal(false)}
        initialChannelMap={{
          ...mapToEdit,
          id: { channelName: mapToEdit?.id?.channelName, model },
        }}
        onSave={async (channelMap) => {
          const pathSuffix = mapToEdit
            ? `/${mapToEdit.id.channelName}+${mapToEdit.id.model}`
            : "";

          try {
            await HTTP_CLIENT.request(mapToEdit ? "PATCH" : "POST", {
              path: `${PATH_PREFIX}${pathSuffix}`,
              headers: { Authorization: `Bearer ${jwt}` },
              body: channelMap,
            });
            await updateChannelMapList();
          } catch (e: any) {
            console.error(e.stack);
            toast.error(e.message);
          }
          setShowModal(false);
        }}
      />
    </>
  );
}
