import React from "react";
import { InMemoryDownload } from "../InMemoryDownload";
import { ArtifactBundle } from "../../models";
import { ArtifactFileList } from "./ArtifactFileList";

interface Props {
  bundleFiles: ArtifactFileList;
  bundle: ArtifactBundle;
}

export function Tr3BootloaderFileList({ bundleFiles, bundle }: Props) {
  return (
    <>
      <td>
        {bundleFiles.bootloaderRelease && (
          <>
            <InMemoryDownload
              displayName="Bootloader"
              fileName={`bootloader-${bundle.version}.bin`}
              content={bundleFiles.bootloaderRelease?.data}
            />
          </>
        )}
        {bundleFiles.bootloaderReleaseElf && (
          <>
            <br />
            <InMemoryDownload
              displayName="Bootloader (ELF)"
              fileName={`bootloader-${bundle.version}.elf`}
              content={bundleFiles.bootloaderReleaseElf?.data}
            />
          </>
        )}
        {bundleFiles.releaseRebootstrapper && (
          <>
            <br />
            <InMemoryDownload
              displayName="Rebootstrapper"
              fileName={`rebootstrapper-${bundle.version}.bin`}
              content={bundleFiles.releaseRebootstrapper?.data}
            />
          </>
        )}
        {bundleFiles.releaseRebootstrapperSfbDevKeys && (
          <>
            <br />
            <InMemoryDownload
              displayName="Rebootstrapper (SFB, Dev Keys)"
              fileName={`rebootstrapper-dev-${bundle.version}.sfb`}
              content={bundleFiles.releaseRebootstrapperSfbDevKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseRebootstrapperSfbProdKeys && (
          <>
            <br />
            <InMemoryDownload
              displayName="Rebootstrapper (SFB, Prod Keys)"
              fileName={`rebootstrapper-prod-${bundle.version}.sfb`}
              content={bundleFiles.releaseRebootstrapperSfbProdKeys?.data}
            />
          </>
        )}
        {bundleFiles.releaseRebootstrapperElf && (
          <>
            <br />
            <InMemoryDownload
              displayName="Rebootstrapper (ELF)"
              fileName={`rebootstrapper-${bundle.version}.elf`}
              content={bundleFiles.releaseRebootstrapperElf?.data}
            />
          </>
        )}
        {bundleFiles.releaseRebootstrapperElf && (
          <>
            <br />
            <InMemoryDownload
              displayName="Rebootstrapper Bundle (Dev keys)"
              fileName={`SBSFU_rebootstrapper-${bundle.version}.bin`}
              content={bundleFiles.releaseRebootstrapperElf?.data}
            />
          </>
        )}
      </td>

      {/* No debug files for TR3 */}
      <td></td>

      {/* No translation files for TR3 */}
      <td></td>
    </>
  );
}
